import { FormattedMessage } from 'react-intl';
import styles from './case-studies-summary.module.scss';
import imgPinPoint from './../../../assets/images/clients/case-studies/case-studies-pinpoint.webp';
import imgAmazon from './../../../assets/images/clients/case-studies/case-studies-amazon.webp';
import imgWelfi from './../../../assets/images/clients/case-studies/case-studies-welfi.webp';
import imgThomsom from './../../../assets/images/clients/case-studies/case-studies-thomson.webp';
import imgErth from './../../../assets/images/clients/case-studies/case-studies-erth.webp';
import imgBudderfly from './../../../assets/images/clients/case-studies/case-studies-budderfly.webp';
import imgDollarGeneral from './../../../assets/images/clients/case-studies/case-studies-dollarGeneral.webp';
import imgDigicel from './../../../assets/images/clients/case-studies/case-studies-digicel.webp';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { AmazonLogo2, BudderflyLogo2, DigicelLogo, DollarGeneralLogo2, ErthLogo2, PinPointLogo2, ThomsonReutersLogo2, WelfiLogo2 } from '../../../components';
import ScrollContainer from 'react-indiana-drag-scroll';


export const CaseStudiesSummaryClients = ({ context }) => {
    //Datos
    const dataCaseStudies = [
        {
            id: 'amazon',
            url: '/amazon',
            img: <img src={imgAmazon} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-amazon' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-12px' }}><AmazonLogo2 size={{ width: '138', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-amazon" />
        },
        {
            id: 'dollar-general',
            url: '/dollar-general',
            img: <img src={imgDollarGeneral} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-dollar-general' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-8px' }}><DollarGeneralLogo2 size={{ width: '171', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-dollar-general" />
        },
        {
            id: 'thomson-reuters',
            url: '/thomson-reuters',
            img: <img src={imgThomsom} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-thomson-reuters' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-8px' }}><ThomsonReutersLogo2 size={{ width: '223', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-thomsom" />
        },
        {
            id: 'budderfly',
            url: '/budderfly',
            img: <img src={imgBudderfly} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-budderfly' />,
            logo: <div style={{ margin: '-10px', marginLeft: '0px' }}><BudderflyLogo2 size={{ width: '143', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-budderfly" />
        },
        {
            id: 'pinpoint',
            url: '/pinpoint',
            img: <img src={imgPinPoint} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-pinpoint' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-8px' }}><PinPointLogo2 size={{ width: '122', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-pinPoint" />
        },

        {
            id: 'welfi',
            url: '/welfi',
            img: <img src={imgWelfi} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-welfi' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-8px' }}><WelfiLogo2 size={{ width: '119', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-welfi" />
        },

        {
            id: 'erth',
            url: '/erth',
            img: <img src={imgErth} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-erth' />,
            logo: <div style={{ margin: '-10px', marginLeft: '0px' }}><ErthLogo2 size={{ width: '93', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-erth" />
        },
        {
            id: 'digicel',
            url: '/digicel',
            img: <img src={imgDigicel} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-digicel' />,
            logo: <div style={{ margin: '-10px', marginLeft: '0px' }}><DigicelLogo size={{ width: '88', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-digicel" />
        },


    ]


    const lang = localStorage.getItem('lang')
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); //Ancho de ventana
    const [isDragging, setIsDragging] = useState(false); //Variable si se esta manejando con drag
    const [cardWidth, setCardWidth] = useState(300); // Ancho de cada card
    const paddingCard = 16;
    const [activeSlide, setActiveSlide] = useState(0);
    const animationRef = useRef(null);
    const [containerRef, isVisible] = useIntersectionObserver();
    const sliderRef = useRef(null);

    useEffect(() => {
        initAnimation();
    }, [isVisible, windowWidth]);

    useEffect(() => {
        // Detiene la animacion si el elemento no esta visible  
        if (!isVisible)
            clearTimeout(animationRef.current);
    }, [isVisible]);

    //Manejo de ancho de ventana
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const scrollContainer = containerRef.current;
        const handleScroll = () => {
            setPosition();
        };
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [containerRef, cardWidth, paddingCard, activeSlide]);

    useEffect(() => {
        const slider = sliderRef.current;
        const sliderWidth = slider.scrollWidth;
        if (windowWidth > 1440) {
            const width = sliderWidth - (paddingCard * 3);
            setCardWidth(width * 0.25);
            return;
        }
        if (windowWidth > 1025) {
            const width = sliderWidth - (paddingCard * 2);
            setCardWidth(width * 0.333);
            return;
        }
        if (windowWidth > 640) {
            const width = sliderWidth - (paddingCard * 1);
            setCardWidth(width * 0.5);
            return;
        }
        const width = sliderWidth;
        setCardWidth(width);
        return;

    }, [windowWidth, sliderRef])


    //Seteo de tiempo de transicion segun ancho de ventana
    const time = () => {

        if (windowWidth > 1280) {
            return 40000
        }
        if (windowWidth > 769) {
            return 45000
        }

        return 50000
    }

    // Manejador de animacion
    const initAnimation = () => {
        const scrollContainer = containerRef.current;

        if (scrollContainer.scrollLeft !== 0 && isDragging) {
            return;
        }
        if (scrollContainer && isVisible) {
            const scrollAmount = scrollContainer.scrollWidth / 2; // Desplazamiento hacia la mitad del contenedor duplicado
            smoothScroll(scrollContainer, scrollAmount, time());
        }

        return () => {
            clearTimeout(animationRef.current); // Limpiar la animación en el desmontaje
        };
    }

    const setPosition = () => {
        const scrollContainer = containerRef.current;
        if(scrollContainer){
            const currentScroll = scrollContainer.scrollLeft;
            const maxScroll = scrollContainer.scrollWidth / 2;
            let currentSlide = Math.trunc(currentScroll / (cardWidth + paddingCard));
    
            if (currentScroll >= maxScroll) {
                currentSlide -= dataCaseStudies.length;
            }
            if (currentSlide !== activeSlide) {
                setActiveSlide(currentSlide)
            }
        }
        
    }
    //Animacion
    const smoothScroll = (element, to, duration) => {

        const start = element.scrollLeft;
        const increment = 10; // Intervalo de tiempo en ms entre cada incremento
        const stepAmount = 0.6;
        let currentTime = 0;
        const animateScroll = () => {
            setPosition();

            if (!isDragging && isVisible) {
                currentTime += increment;
                element.scrollLeft = start + currentTime * stepAmount / increment;
                if (currentTime < duration) {
                    animationRef.current = setTimeout(animateScroll, increment);
                } else {
                    //Agrego 10 para evitar diferencia de decimales
                    if (element.scrollLeft + 10 >= element.scrollWidth / 2) {
                        element.scrollLeft = 0;
                    }
                    smoothScroll(element, element.scrollWidth / 2, time());
                }
            }
        };
        animateScroll();
    };

    const handleMouseEnter = () => {
        clearTimeout(animationRef.current); // Pausar la animación al hacer hover
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
        initAnimation(); // Reanudar la animación cuando el mouse sale
    };

    const handleDragStart = () => {
        if (!isDragging)
            setIsDragging(true);
        console.log('drag')
        clearTimeout(animationRef.current); // Detener la animación en caso de que esté en progreso cuando comienza a hacer drag
    };

    const handleTouchEnd = () => {
        setPosition();

    }


    const handleLeftClick = () => {
        setIsDragging(true);
        clearTimeout(animationRef.current);
        const scrollContainer = containerRef.current;
        const currentScroll = scrollContainer.scrollLeft;

        // Resto el desplazamiento parcial para mover una card completa
        const partialScroll = currentScroll % (cardWidth + paddingCard);

        // Si el scroll se encuentra al incio, avanza el ancho de una card completa
        const scrollAmount = partialScroll === 0 ? (cardWidth + paddingCard) : partialScroll;

        scrollContainer.scrollTo({
            left: Math.max(0, currentScroll - scrollAmount),
            behavior: "smooth"
        });

        // Comprobar si llegó al principio y mover al final para el efecto infinito
        if (scrollContainer.scrollLeft <= 10) {
            scrollContainer.scrollLeft = (scrollContainer.scrollWidth / 2); // Mover al final
        }
    };

    const handleRightClick = () => {

        setIsDragging(true);
        const scrollContainer = containerRef.current;
        const currentScroll = scrollContainer.scrollLeft;
        const maxScroll = scrollContainer.scrollWidth - scrollContainer.clientWidth;
        const halfScrollWidth = scrollContainer.scrollWidth / 2; // La mitad del contenedor duplicado

        // Calcular el desplazamiento necesario para la próxima tarjeta
        const partialScroll = currentScroll % (cardWidth + paddingCard);
        const scrollAmount = (cardWidth + paddingCard) - partialScroll;

        // Mover el scroll hacia la derecha
        const newScrollPosition = scrollAmount < 10 ? currentScroll + (cardWidth + paddingCard) : currentScroll + scrollAmount;
        scrollContainer.scrollTo({
            left: Math.min(maxScroll, newScrollPosition),
            behavior: "smooth"
        });

        // Verificar si estamos al final de la primera mitad del contenedor
        if (newScrollPosition >= halfScrollWidth) {
            // Saltar imperceptiblemente a la misma posición en la segunda mitad del contenedor
            setTimeout(() => {
                const adjustedScrollPosition = newScrollPosition - halfScrollWidth;
                scrollContainer.scrollLeft = adjustedScrollPosition;
            }, 300); // Ajuste de tiempo para que el scroll suave se complete antes de hacer el ajuste
        }
    };

    const goTo = (index) => {
        setIsDragging(true);
        clearTimeout(animationRef.current);
        const scrollContainer = containerRef.current;
        const to = (cardWidth + paddingCard) * index;
        setActiveSlide(index);
        scrollContainer.scrollTo({
            left: to,
            behavior: "smooth"
        });
    }


    return (
        <div className={context === 'clients' ? styles.background__white : styles.background}>
            <div className={styles.container} >
                {context !== 'clients' && <h3><FormattedMessage id="clients.caseStudies-title" /></h3>}
                <div className={styles.slider} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onDrag={handleDragStart} onDragStart={handleDragStart} onTouchEnd={handleTouchEnd} onMouseDown={handleDragStart} onClick={handleDragStart} onTouchStart={handleDragStart}>
                    <div
                        className={styles.arrow__left}
                        onClick={handleLeftClick}
                    >
                        <svg width="21" height="38" viewBox="0 0 21 38" fill="none">
                            <path
                                d="M19.7852 36.059L1.78515 18.559L19.7852 1.05896"
                                stroke="#00D264"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div ref={sliderRef} className={styles.slider__container}>
                        <ScrollContainer className={styles["clientsSummary-slider"]} vertical={false} innerRef={containerRef} >
                            {[...dataCaseStudies, ...dataCaseStudies].map((client, index) => {
                                const renderClient = () => {
                                    return (
                                        <div style={{ minHeight: 500, boxSizing: 'border-box' }} className={styles["slick-slide"]}>
                                            {client.img}
                                            {client.logo}
                                            <p>
                                                {client.text}
                                            </p>
                                            <div className={styles.viewCase}  >
                                                <span>
                                                    <FormattedMessage id="clients.caseStudies-link" />
                                                </span>
                                                <svg
                                                    width="17"
                                                    height="17"
                                                    viewBox="0 0 17 17"
                                                    fill="none"
                                                    className={styles.arrowView}
                                                >
                                                    <path
                                                        d="M1.5 15.5L16 1M16 1H1.5M16 1V13.5"
                                                        stroke="currentColor"
                                                        strokeWidth="1"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    )
                                }
                                return (
                                    <Link to={`/${lang}/clients${client.url}`} className={styles.item__clients} key={`${client.id}-${index}`} style={{ width: cardWidth, minWidth: cardWidth }}>
                                        {renderClient()}
                                    </Link>
                                )
                            })}

                        </ScrollContainer>
                    </div>
                    <div
                        className={styles.arrow__right}
                        onClick={handleRightClick}
                    >
                        <svg width="21" height="40" viewBox="0 0 21 40" fill="none">
                            <path
                                d="M1.78516 1.6095L19.7852 20.1095L1.78516 38.6095"
                                stroke="#00D264"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div className={styles.dots__container}>
                    {dataCaseStudies.map((step, index) => {
                        return (
                            <div key={`${step.id}-${index}`} className={activeSlide === index ? styles["dot--selected"] : styles.dot} onClick={() => goTo(index)} />
                        )
                    })}

                </div>
            </div>
        </div>

    )
}